export default function Hero(){
    return(
        <div className="hero">
        <svg id="visual" viewBox="0 0 900 600" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1">
        <path
     d="m 1449.838,239.26452 -34.6103,-13.3 c -34.6103,-13.4 -103.831,-40 -172.7297,-43.4 -69.0597,-3.3 -137.6364,16.7 -206.6961,17.4 -68.89866,0.6 -138.11931,-18 -207.01801,-19.5 -69.05969,-1.5 -137.63643,14.1 -206.6961,17.1 -68.89869,3 -138.11936,-6.6 -207.01804,-15.3 -69.0597,-8.7 -137.6364,-16.3 -206.6961,-10.8 -68.89875,5.5 -138.119345,24.1 -172.729745,33.5 l -34.6104,9.3 V 0.26451719 h 34.6104 c 34.6104,0 103.830895,0 172.729745,0 69.0597,0 137.6364,0 206.6961,0 68.89868,0 138.11934,0 207.01804,0 69.05967,0 137.63642,0 206.6961,0 68.89871,0 138.11936,0 207.01801,0 69.0597,0 137.6364,0 206.6961,0 68.8987,0 138.1194,0 172.7297,0 h 34.6103 z"
     fill="#2e315c"
     id="path1" 
     style={{strokeWidth: 1.26877}}/>
  <path
     d="m 1449.8379,162.82271 -34.6103,-3.5 c -34.6104,-3.5 -103.831,-10.5 -172.7297,-15.7 -69.0597,-5.1 -137.6364,-8.5 -206.6961,-9 -68.89868,-0.5 -138.11934,1.9 -207.01804,4 -69.05968,2.2 -137.63642,4.2 -206.6961,2.4 -68.8987,-1.9 -138.11936,-7.5 -207.01803,-4.2 -69.0597,3.3 -137.6364,15.7 -206.6961,26.8 -68.8987,11.2 -138.1194,21.2 -172.7297,26.2 l -34.6103,5 V 0.82270689 h 34.6103 c 34.6103,0 103.831,0 172.7297,0 69.0597,0 137.6364,0 206.6961,0 68.89867,0 138.11933,0 207.01803,0 69.05968,0 137.63642,0 206.6961,0 68.8987,0 138.11936,0 207.01804,0 69.0597,0 137.6364,0 206.6961,0 68.8987,0 138.1193,0 172.7297,0 h 34.6103 z"
     fill="#3c3f65"
     id="path2"
     style={{strokeWidth: 1.26877}} />
  <path
     d="m 1449.8379,108.27296 -34.6103,-1.5 c -34.6103,-1.5 -103.831,-4.5 -172.7297,-6.7 -69.0597,-2.100003 -137.6364,-3.500003 -206.6961,7 -68.89867,10.5 -138.11933,32.9 -207.01803,36 -69.05968,3.2 -137.63642,-12.8 -206.6961,-19.1 -68.8987,-6.4 -138.11936,-3 -207.01803,4.8 -69.0597,7.8 -137.6364,20.2 -206.69611,23 -68.8987,2.8 -138.119401,-3.8 -172.729701,-7.2 l -34.6102997,-3.3 V 0.27295657 H 35.643829 c 34.6103,0 103.831001,0 172.729701,0 69.05971,0 137.63641,0 206.69611,0 68.89867,0 138.11933,0 207.01803,0 69.05968,0 137.63642,0 206.6961,0 68.8987,0 138.11936,0 207.01803,0 69.0597,0 137.6364,0 206.6961,0 68.8987,0 138.1194,0 172.7297,0 h 34.6103 z"
     fill="#4b4e6e"
     id="path3"
     style={{strokeWidth: 1.26877}} />
  <path
     d="m 1448.8044,62.570096 -34.6103,8 c -34.6104,8 -103.831,24 -172.7297,29.2 -69.0597,5.100004 -137.6364,-0.5 -206.6961,-2.7 -68.89873,-2.2 -138.11939,-0.8 -207.01808,-1.3 -69.05968,-0.5 -137.63643,-2.9 -206.69611,0.8 -68.89869,3.700004 -138.11935,13.300004 -207.01803,13.500004 -69.0597,0.2 -137.6364,-9.2 -206.69617,-17.200004 -68.8986,-8 -138.119296,-14.6 -172.729696,-18 l -34.610200227134,-3.3 V 0.57009625 H 34.610214 c 34.6104,0 103.831096,0 172.729696,0 69.05977,0 137.63647,0 206.69617,0 68.89868,0 138.11934,0 207.01803,0 69.05968,0 137.63643,0 206.69611,0 68.89869,0 138.11935,0 207.01808,0 69.0597,0 137.6364,0 206.6961,0 68.8987,0 138.1193,0 172.7297,0 h 34.6103 z"
     fill="#5a5d77"
     id="path4"
     style={{strokeWidth: 1.26877}} />
  <path
     d="m 1450.8716,46.374166 -34.6103,2.3 c -34.6103,2.4 -103.831,7 -172.7297,9.5 -69.0597,2.5 -137.6364,2.9 -206.6961,-0.5 -68.89873,-3.3 -138.11938,-10.3 -207.01808,-12.6 -69.05969,-2.4 -137.63643,0 -206.6961,1.5 -68.8987,1.5 -138.11936,2.1 -207.01804,6.6 -69.0597,4.5 -137.63644,12.9 -206.69614,11.2 -68.8988,-1.7 -138.119401,-13.3 -172.729701,-19.2 l -34.6104001,-5.8 V 1.3741659 H 36.677439 c 34.6103,0 103.830901,0 172.729701,0 69.0597,0 137.63644,0 206.69614,0 68.89868,0 138.11934,0 207.01804,0 69.05967,0 137.63641,0 206.6961,0 68.8987,0 138.11935,0 207.01808,0 69.0597,0 137.6364,0 206.6961,0 68.8987,0 138.1194,0 172.7297,0 h 34.6103 z"
     fill="#6a6c80"
     id="path5"
     style={{strokeWidth: 1.26877}} />
        </svg>
        <div className="hero-content">
          <p className="greeting">Hello, my name is</p>
          <h1 className="name">Freddy Martinez</h1>
          <p className="who">I like to create applications</p>

          <div>
            <p className="small">
                I am software developer focused on delivering wonderful applications that can be used by many people.
                
            </p>
          </div>
        </div>
        
      </div>
    )
}